:root {
      /*--colores--*/
      --color-principal: #08a33e;
      --color-principal-dark: rgb(5, 94, 36);
      --color-principal-opacy: #d5f7dd;
      --color-body: #F0F2F1;
      --color-secundario: #f8b80e;
      --color-white: #ffffff;
      --color-text: #122618;
      --color-grey: #e9e9e9;
      --color-grey-dark: #c2c2c2;
}

.content-info {
  margin: auto;
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-text);
}


.info {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 90%;
}

.box-info,
.search-bar {
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--color-grey-dark);
  border-radius: 20px;
}

.search-bar {
  border: 2px solid var(--color-principal);
}

.search-bar {
  font-size: 18px;
  width: auto;
  padding: 20px;
  height: calc(50px - 35px);
  outline: none;
}

h1 {
  margin: 8px;
  margin-bottom: 24px;
}

h4 {
  margin: 8px;
}

p {
  margin-left: 16px;
}

button {
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 42px;
  border-radius: 12px;
  border: none;
}

.box-button-copy:focus {
  background-color: var(--color-grey-dark)
}

.button-copy {
  font-size: 20px;
  margin: 5px;
}

.progress-bar-container {
  width: 90%;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
  overflow: hidden;
}

.progress-bar {
  transition: width 0.5s ease;
}

.swal2-styled {
  width: 100%;
  background-color: var(--color-principal);
}

.progress-bar {
  background-color: var(--color-principal);
  height: 10px;
}

.content-expired {
  text-align: center;
  margin: 50px auto;
  padding: 20px;
  border: 2px solid #ff4d4f;
  background-color: #fff1f0;
  color: #d32f2f;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.content-expired h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.content-expired p {
  font-size: 16px;
}
